import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';


// 创建问题记录
export function createProblemItem(){
    return request('/problem/createProblemItem.do',"POST",{userId:SystemUtils.loginUser.id,nameCN:SystemUtils.loginUser.name})
}


// 获取任务单信息
export function getProjectStage(data){
    return request('/problem/getProjectStage.do',"POST",data)
}


// 获取任务单下的设计序号
export function getDesignNumber(data){
    return request('/problem/getDesignNumber.do',"POST",data)
}

// 获取任务单下的设计序号
export function getVehicleCode(data){
    return request('/problem/getVehicleCode.do',"POST",data)
}

// 获取任务单下的设计序号
export function updateProblemItem(data){
    return request('/problem/updateProblemItem.do',"POST",Object.assign({userId:SystemUtils.loginUser.id,nameCN:SystemUtils.loginUser.name,account:SystemUtils.loginUser.account},data))
}
// 上传图片
export function uploadImage(data){
    return request('/problem/uploadImage.do',"POST",data)
}

// 删除图片
export function deleteImage(data){
    return request('/problem/deleteImage.do',"POST",data)
}

// 获取零件信息
export function getPartCode(data){
    return request('/problem/getPartCode.do',"POST",data)
}


import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 根据出库单id获取出库单明细 扫描二维码
export function getDeliveryInfo(data){
    return request('/workshop/getDeliveryInfo.do',"POST",data)
}
// 出库单提交
export function deliveryPublish(data){
    return request('/storeDelivery/deliveryPublish.do',"POST",Object.assign({userId:SystemUtils.loginUser.id,user:SystemUtils.loginUser.name,userAccount:SystemUtils.loginUser.account,type:1},data))
}
// 申请人
export function getApplyPerson(data){
    return request('/storeDelivery/getApplyPerson.do',"POST",data)
}

// 获取出库单信息(多种方式)
export function getDeliveryInfoByType(data){
    return request('/workshop/getDeliveryInfoByType.do',"POST",data)
}
// 删除出库明细
export function deleteDeliveryItem(data){
    return request('/storeDelivery/deleteDeliveryItem.do',"POST",data)
}

// 保存出库单
export function updateDeliveryItem(data){
    return request('/storeDelivery/updateDeliveryItem.do',"POST",Object.assign({userId:SystemUtils.loginUser.id,user:SystemUtils.loginUser.name},data))
}
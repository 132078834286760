<template >
  <Header
    type="0"
    title="库位"
    :isBack="false"
    :btnIconRight="['icon-guanbi']"
    @transfer="transfer"
  >
  </Header>
  <ion-content class="ion-padding" id="ProjectMainProjectList">
    <div class="card-content" v-for="(item,idx) in list" :key="idx">
      <i
        class="iconfont icon-touxiangshanchu delPart"
        @click.stop="delPart(idx)"
        v-if="canRemove"
      ></i>
      <ion-text class="part">{{item.str}}</ion-text>
    </div>
  </ion-content>
  <ion-footer v-if="canRemove">
    <ion-button expand="block" @click="commit">保存</ion-button>
  </ion-footer>
</template>
<script>
import { defineComponent } from "vue";
import {
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
export default defineComponent({
  components: {
    Header,
  },
  props: {
    localeCodes: [String, Number],
    stockLoacleIds: [String, Number],
    canRemove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  mounted(){
      this.delList()
  },
  methods: {
    transfer() {
      modalController.dismiss();
    },
    commit() {
        var _this = this
      modalController.dismiss({
        list: _this.list,
      });
    },
    delList(){
        var ids = this.stockLoacleIds?this.stockLoacleIds.split(','):[]
        var strs = this.localeCodes?this.localeCodes.split(','):[]
        console.log(this.stockLoacleIds,this.localeCodes)
        for(var i=0;i<strs.length;i++){
            this.list.push({id:ids[i]||i,str:strs[i]})
        }
    },
    delPart(idx){
        this.list.splice(idx,1)
    }
  },
});
</script>
<style lang="scss" scoped>
.app-container {
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;

  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.card-content {
  padding: 15px 15px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 5px 18px 0px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  position: relative;
  .delPart {
    position: absolute;
     right: 10px;
    top: 15px;
  }
  > div {
    padding: 15px 0 15px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<template >
  <ion-page>
    <Header
      type="0"
      title="零件出库"
      :btnIconRight="['icon-naotujia']"
      @transfer="transfer"
    >
    </Header>

    <ion-content scroll-y="true" class="content" id="personChoose">
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>试制任务单号</div>
          <div class="right">
            <div class="searchBox">
              <input
              style="width:130px"
                @keyup.enter="searchHandler"
                @input="scan"
                placeholder="请输入任务单号"
                v-model="queryParam.stageCode"
              />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>出库单</div>
          <div class="right">
            <ion-select
              placeholder="请选择出库单"
              @ionChange="ionChange(queryParam.id)"
              v-model="queryParam.id"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
            >
              <ion-select-option
                v-for="(item, index) in ckdOptions"
                :key="index"
                :value="item.id"
                >{{ item.code }}</ion-select-option
              >
            </ion-select>
            <!-- <ion-text @click="openQrScanner">扫一扫</ion-text> -->
          </div>
        </div>
      </div>

      <!-- 无数据   -->
      <div class="no_data" v-if="queryParam.datas.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div
        v-for="(item, idx) in queryParam.datas"
        :key="item.id"
        class="app-container"
      >
        <i
          class="iconfont icon-touxiangshanchu delPart"
          @click.stop="delPart(item, idx)"
        ></i>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">库存</div>
          <div class="item-content">{{ item.stockNum }}</div>
        </div>
        <div class="item">
          <div class="item-label">出库数量</div>
          <div class="item-content">
            <ion-input
              v-model.number="item.outNum"
              @ionChange="changeNum(item, idx)"
              placeholder="请输入出库数量"
              style="text-align: right; --padding-end: 0"
            ></ion-input>
          </div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 25px"
        >
          <div class="item-label">库位</div>
          <div class="item-content">
            <ion-text @click.stop="storeReview(item, idx)">
              <span class="addBorderRight">{{ item.localeCodes }}</span>
            </ion-text>
          </div>
        </div>
      </div>
      <CardLoading
        v-if="queryParam.datas.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>

    <ion-footer style="background: #fff" v-if="hidshow">
      <div class="title-canvars">
        <span>取货人签字：</span><span @click="clearCanvars">清除</span>
      </div>
      <div class="canvars-style" style="">
        <canvarsCom ref="canvas"></canvarsCom>
      </div>
      <ion-button expand="block" @click="saveWords">保存</ion-button>
      <ion-button expand="block" @click="commit">提交</ion-button>
    </ion-footer>
    <qrScanner v-if="showScan" @closeScan="closeScan"></qrScanner>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  menuController,
  loadingController,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import canvarsCom from "./canvarsCom";
import qrScanner from "@/components/cordova/qrScanner.vue";
import {
  getDeliveryInfo,
  deliveryPublish,
  getDeliveryInfoByType,
  deleteDeliveryItem,
  updateDeliveryItem,
} from "@/api/storeManagement/partsofWarehouse";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import SystemUtils from "@/utils/SystemUtils";
import SearchBar from "@/components/SearchBar.vue";
import storeReview from "./storeReview.vue";
export default {
  name: "partsofWarehouse",
  components: {
    canvarsCom,
    IonSelect,
    IonSelectOption,
    Header,
    uploadImg,
    CardLoading,
    ionAlert,
    qrScanner,
    SearchBar,
    storeReview,
  },
  data() {
    return {
      qrCode,
      islist: false, // isloading
      showScan: false, // 确认框
      light: false,
      isOpenScan: false,
      queryParam: {
        datas: [],
      },
      person: [],
      ckdOptions: [],
      timer: null,
      docmHeight:0,  //默认屏幕高度
      hidshow:true  //显示或者隐藏footer
    };
  },
  ionViewWillEnter() {},
  mounted() {
    // this.getDeliveryInfo(11123)
    if (this.$route.params.queryParam) {
      var queryParam = JSON.parse(this.$route.params.queryParam);
      this.getDeliveryInfo(queryParam.id);
    }

    this.docmHeight = document.documentElement.clientHeight;//获取当前屏幕高度
    window.onresize = () => {//屏幕高度变化时判断
      return (() => {
        let showHeight = document.body.clientHeight;
        this.hidshow = this.docmHeight > showHeight ? false : true;
      })();
    };
  },
  methods: {
    // 清除签名
    clearCanvars() {
      this.$refs["canvas"].reset();
    },

    searchHandler(searchParam) {
      console.log(searchParam.target.value.trim());
      if (Utils.isInCludeEmoji(searchParam.target.value)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.getCkList({
        type: 1,
        code: "",
        id: "",
        stageCode: searchParam.target.value.trim(),
      });
    },
    // 获取出库单list
    async getCkList(val) {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });
      await loading.present();
      getDeliveryInfoByType({
        type: 1,
        code: val.code,
        id: val.id,
        stageCode: val.stageCode,
        userId: SystemUtils.loginUser.id,
      }).then((res) => {
        loading.dismiss();
        if (!res.data.isError && res.data.data.isSuccess) {
          if (res.data.data.resultData) {
            this.ckdOptions = res.data.data.resultData;
          } else if (res.data.data.item) {
            this.ckdOptions = [];
            this.ckdOptions.push(res.data.data.bill);
            this.queryParam = res.data.data.bill;
            this.queryParam.datas = res.data.data.item;
          }
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    dealOption(val) {
      return new Promise((resolve) => {
        getDeliveryInfoByType({
          type: 1,
          code: val.code,
          id: val.id,
          stageCode: val.stageCode,
          userId: SystemUtils.loginUser.id,
        }).then((res) => {
          if (res.data.data.resultData) {
            this.ckdOptions = res.data.data.resultData;
          } else if (res.data.data.item) {
            this.ckdOptions = [];
            this.ckdOptions.push(res.data.data.bill);
            this.queryParam = res.data.data.bill;
            this.queryParam.datas = res.data.data.item;
          }
          resolve();
        });
      });
    },
    transfer(type) {
      console.log("img");
      this.$router.replace({ path: "/createOutWarehouse" });
    },
    // 更改接收数量
    changeNum(item, idx) {
      console.log(item);
      if (
        item.outNum == 0 ||
        item.outNum == "" ||
        item.outNum == null ||
        item.outNum == undefined
      ) {
      } else if (item.partStockNum - item.partStockNum1 < 0) {
        this.queryParam.datas[idx].outNum = 0;
        Utils.presentToastTopWarning("该零件被占用", "danger");
      } else if (item.outNum + item.partStockNum1 > item.partStockNum) {
        Utils.presentToastTopWarning("该零件被占用", "danger");
        this.queryParam.datas[idx].outNum =
          item.partStockNum - item.partStockNum1;
      }
    },
    openChooseOrganizationModal() {},
    async commit() {
      const _this = this;
      if (!_this.queryParam.id) {
        Utils.presentToastTopWarning("请选择出库单", "danger");
        return false;
      }

      for (var i = 0; i < _this.queryParam.datas.length; i++) {
        if (
          _this.queryParam.datas[i].outNum == 0 ||
          _this.queryParam.datas[i].outNum == null ||
          _this.queryParam.datas[i].outNum == "" ||
          _this.queryParam.datas[i].outNum == undefined
        ) {
          Utils.presentToastTopWarning(
            `零件"${_this.queryParam.datas[i].partNameCN}"出库数量不能为0`,
            "danger"
          );
          return false;
        }
      }
      if (_this.queryParam.datas.length == 0) {
        Utils.presentToastTopWarning(`无可出库零件`, "danger");
        return false;
      }
      const user = SystemUtils.loginUser;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交出库单",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
              _this.queryParam.imageUrl = await this.$refs["canvas"].generate();
              deliveryPublish(_this.queryParam).then((res) => {
                loading.dismiss();

                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async saveWords() {
      const _this = this;
      if (!_this.queryParam.id) {
        Utils.presentToastTopWarning("请选择出库单", "danger");
        return false;
      }
      if (_this.queryParam.datas.length == 0) {
        Utils.presentToastTopWarning(`无可出库零件`, "danger");
        return false;
      }

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });
      await loading.present();

      updateDeliveryItem(_this.queryParam).then((res) => {
        loading.dismiss();

        if (!res.data.isError && res.data.data.isSuccess) {
          _this.getDeliveryInfo(_this.queryParam.id);
          Utils.presentToastTopWarning("操作成功", "success");
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    openQrScanner() {
      this.showScan = true;
    },
    async getDeliveryInfo(id) {
      var _this = this;
      if (this.timer) {
        return false;
      }
      this.timer = setTimeout(() => {
        _this.timer = null;
      }, 1000);
      _this.islist = true;
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });
      await loading.present();
      getDeliveryInfo({ id: id }).then(async (res) => {
        _this.islist = false;
        loading.dismiss();
        if (!res.data.isError && res.data.data.isSuccess) {
          _this.queryParam.id = "";

          if (res.data.data.bill.code) {
            await _this.dealOption({
              type: 1,
              code: "",
              id: id,
              stageCode: res.data.data.bill.stageCode,
            });
          }
          _this.queryParam = res.data.data.bill || { datas: [] };
          _this.queryParam.datas = res.data.data.item || [];
          _this.$forceUpdate();
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    ionChange(id, content) {
      console.log("change");
      this.getDeliveryInfo(id);
      // getDeliveryInfo({ id: id }).then((res) => {
      //   this.islist = false;
      //   if (!res.data.isError && res.data.data.isSuccess) {
      //     this.tableList = res.data.data.item || [];
      //     for (var i = 0; i < this.tableList.length; i++) {
      //       this.tableList[i].receiveNum = 0;
      //       if (this.tableList[i].partCode == content.partCode) {
      //         this.tableList[i].receiveNum = 1;
      //       }
      //     }
      //   } else {
      //     Utils.presentToastTopWarning(
      //       res.data.message || res.data.data.message,
      //       "danger"
      //     );
      //   }
      // });
    },
    scan(val) {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.queryParam.stageCode;
        if (scanVal.indexOf('"') > -1) {
          this.queryParam.stageCode = "";
          if (scanVal.indexOf("id") > -1) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            this.getCkList({
              type: 1,
              code: "",
              id: contents.id,
              stageCode: "",
            });
          } else {
            this.queryParam.stageCode = "";
            Utils.presentToastTopWarning("请扫描出库单二维码", "danger");
          }
        }
      });
      // var scanVal = val.detail.value;
      // console.log(val.detail.value);
      // if (scanVal.indexOf('"') > -1) {
      //   this.queryParam.stageCode = "";
      //   if (scanVal.indexOf("id") > -1) {
      //     var contents = JSON.parse(
      //       "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
      //     );
      //     this.getCkList({
      //       type: 1,
      //       code: "",
      //       id: contents.id,
      //       stageCode: "",
      //     });
      //   } else {
      //     this.queryParam.stageCode = "";
      //     Utils.presentToastTopWarning("请正确扫描二维码", "danger");
      //   }
      // }
    },
    closeScan(contents) {
      this.showScan = false;
      if (contents.id) {
        this.getDeliveryInfo(contents.id);
      } else {
        Utils.presentToastTopWarning("请正确扫描二维码", "danger");
      }
    },
    async delPart(item, idx) {
      const _this = this;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否删除该零件",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();

              deleteDeliveryItem({
                datas: [item.id],
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.queryParam.datas.splice(idx, 1);
                  Utils.presentToastTopWarning("删除成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "warning"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async storeReview(item, idx) {
      var _this = this;
      console.log(item.localeCodes);
      const modal = await modalController.create({
        component: storeReview,
        cssClass: "my-custom-class",
        componentProps: {
          localeCodes: item.localeCodes,
          stockLoacleIds: item.stockLoacleIds,
          canRemove: false,
        },
      });
      modal.present();
      var data = await modal.onDidDismiss();
      if (!data.data) {
        return false;
      }
      var list = data.data.list;
      var list1 = [];
      var list2 = [];
      list.forEach((e) => {
        list1.push(e.id);
        list2.push(e.str);
      });
      this.queryParam.datas[idx].stockLoacleIds = list1.join(",");
      this.queryParam.datas[idx].localeCodes = list2.join(",");
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
.selectBtn {
  width: 60px;
  height: 30px;
}
.title-canvars {
  background-color: #ffffff;
  font-size: px2rem(20);
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.canvars-style {
  margin: 0 auto;
  border: 1px solid #999;
  border-radius: 10px;
  overflow: hidden;
  width: calc(100% - #{px2rem(20)});
  height: 100px;
  background: #fff;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  position: relative;
  .delPart {
    position: absolute;
    right: -5px;
    top: -5px;
  }
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        height: px2rem(36);
        white-space: nowrap;
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.no_data {
  padding-top: 0;
}
input {
  border: 0;
  outline: none;
  text-align: right;
}
</style>
<template>
  <div class="getPhoto">
  

    <div
      v-for="(item, idx) in dataList"
      class="uploadImg"
      :key="idx"
      :data-idx="idx"
      @click="preview(idx)"
    >
      <img :src="item" />
      <i
        class="iconfont icon-touxiangshanchu"
        v-if="canRemove"
        @click.stop="delImg(idx)"
      ></i>
    </div>
      <span
      class="iconfont"
      v-if="maxLength > form[photo].length"
      result-type="dataUrl"
    >
      <van-uploader capture="camera" :after-read="afterRead">
        <ion-img :src="require('@/assets/images/add.png')"></ion-img>
      </van-uploader>
    </span>
  </div>
</template>

<script>
import { uploadImage, deleteImage } from "@/api/questionManage/questionAdd";
import { ImagePreview } from "vant";
import Utils from "@/utils/Utils.ts";
export default {
  name: "Tab2",
  props: {
    form: {
      //表单model
      type: Object,
      required: true,
    },
    photo: {
      //字段名
      type: String,
      required: true,
    },
    maxLength: {
      //最大上传数量
      type: Number,
      default: 1,
    },
    id: [String, Number],
    canRemove: {
      //是否有删除按钮
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dataList: function () {
      var list = [];
      this.form[this.photo].forEach((e) => {
        list.push(e.url.slice(e.url.indexOf('/JFVBM')));
      });
      return list;
    },
  },
  data() {
    return {
      spaceId: "",
    };
  },
  mounted() {
    // this.form[this.photo] = await Promise.all(
    //   this.form[this.photo].map(async (e) => {
    //     const a = await savePicture(e, e.id);
    //     return a;
    //   })
    // );
    document.addEventListener("deviceready", this.onDeviceReady(), false);
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      uploadImage({ id: this.id, image: file.content }).then((res) => {
        var data = res.data.data.resultData[0]
        data.url = data.url.slice(data.url.indexOf('/JFVBM'))
        this.form[this.photo].push(data);
      });
    },
    delImg(idx) {
      deleteImage({ fileId: this.form[this.photo][idx].fileId }).then((res) => {
        if (!res.data.isError && res.data.data.isSuccess) {
          Utils.presentToastTopWarning("操作成功", "success");
          this.form[this.photo].splice(idx, 1);
        }else{
          Utils.presentToastTopWarning(res.data.message||res.data.data.message||res.data.data.resultData.message, "warning");
        }
      });
    },
    onDeviceReady() {},
    preview(idx) {
      console.log(this.dataList, idx);
      ImagePreview({
        images: [this.dataList[idx]],
        startPosition: 0,
        closeable:true
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.getPhoto {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  .uploadImg {
    width: 40px;
    height: 40px;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
    i {
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }
}
.iconfont {
  font-size: 40px;
  opacity: 0.5;
}
.icon-touxiangshanchu {
  font-size: 10px;
}
</style>
<template>
  <div class="qrscanner">
    <div class="header" id="close-scanner-button">
      <div @click="closeScan()">取消</div>
      <div>扫码</div>
    </div>
    <div class="qrscanner-area"></div>
    <div class="through-line"></div>
  </div>
</template>

<script>
export default {
  name: "scan",
  data() {
    return {
      contents: {},
    };
  },
  beforeCreate() {
    //document.getElementById("app").style.backgroundColor = "transparent";
    //document.querySelector("body").style.backgroundColor = "transparent";
    document.querySelector("html").classList.add("qr-scanner-open");
  },
  mounted() {
    this.openQrScanner();
  },
  methods: {
    openQrScanner() {
      var _this = this;
      try {
        QRScanner.show((status) => {
          //alert("[Scan.vue onScan] 显示扫描" + JSON.stringify(status));
        });
        QRScanner.scan((err, contents) => {
          if (err) {
            alert(JSON.stringify(err));
          } else {
            alert("扫描结果: " + contents);
            _this.contents = JSON.parse("{" + contents + "}");
            _this.closeScan();
          }
        });
      } catch (e) {
        console.log("[Scan.vue：onScan] " + JSON.stringify(e));
      }
    },
    openLight() {
      QRScanner.destroy();
      try {
        if (!this.light) {
          QRScanner.enableLight((err, status) => {
            err &&
              console.log(
                "[Scan.vue] 打开手电筒状态错误：" + JSON.stringify(err)
              );
            console.log("[Scan.vue] 打开手电筒状态：" + JSON.stringify(status));
          });
        } else {
          QRScanner.disableLight((err, status) => {
            err &&
              console.log(
                "[Scan.vue] 关闭手电筒状态错误：" + JSON.stringify(err)
              );
            console.log("[Scan.vue] 关闭手电筒状态：" + JSON.stringify(status));
          });
        }
      } catch (e) {
        console.log("[Scan.vue] " + JSON.stringify(e));
        return;
      }
      this.light = !this.light;
    },
    distroyScanner() {
      //恢复之前的背景
      try {
        QRScanner.hide((status) => {
          console.log("[Scan.vue] 关闭扫描" + JSON.stringify(status));
        });
        QRScanner.destroy(function (status) {
          console.log("[Scan.vue] 销毁扫码" + JSON.stringify(status));
        });
      } catch (e) {
        console.log("[Scan.vue]" + JSON.stringify(e));
      }
    },
    closeScan() {
      document.querySelector("html").classList.remove("qr-scanner-open");
      this.distroyScanner();
      this.$emit("closeScan", this.contents);
    },
  },

  beforeUnmount() {
    this.distroyScanner();
  },
};
</script>

<style lang="scss">
html.qr-scanner-open {
  background-color: transparent !important;
  background: transparent none !important;
  ion-app,
  ion-content {
    // height: 70px !important;
  }
  body,
  ion-app,
  ion-page,
  .app-root,
  ion-nav,
  .nav-decor,
  ion-content,
  .viewscan,
  .fixed-content,
  .scroll-content {
    background-color: transparent !important;
    background: transparent none !important;
  }
  Header,
  header {
    height: 29px;
  }
  .header {
    color: white;
    height: 50px;
    padding: 15px 10px;
    background: rgba(3, 3, 3, 0.5);
    font-size: 16px;
    position: relative;
    > div:nth-of-type(1) {
      position: absolute;
      left: 10px;
      top: 15px;
    }
    > div:nth-of-type(2) {
      width: 100%;
      text-align: center;
    }
  }
  ion-content {
    --background: none transparent;
  }
  [app-viewport],
  [overlay-portal],
  [nav-viewport],
  [tab-portal],
  .nav-decor {
    display: none !important;
    background: none transparent !important;
  }

  .qrscanner {
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    &-area {
      width: 100%;
      height: 100%;
      background: url(../../assets/images/scanner.svg) no-repeat center center;
      background-size: contain;
    }
  }
  .through-line {
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 2px;
    background: red;
    position: absolute;
    animation: myfirst 2s linear infinite alternate;
  }
  @keyframes myfirst {
    0% {
      background: red;
      top: 40%;
    }
    25% {
      background: yellow;
      top: 45%;
    }
    50% {
      background: blue;
      top: 50%;
    }
    75% {
      background: green;
      top: 55%;
    }
    100% {
      background: red;
      top: 60%;
    }
  }
  .button-bottom {
    width: 128px;
    position: absolute;
    left: 50%;
    bottom: 80px;
    margin-left: -64px;
    .icon-camera {
      float: left;
    }
  }

  ion-content {
    --overflow: hidden !important;
  }

  ion-header,
  ion-footer,
  ion-content > *:not(#close-scanner-button) {
    display: none;
  }
}
.hide {
  --background: none transparent;
}
</style>